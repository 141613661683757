import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    shop_id: null,
  },
  mutations: {
    saveToken(state, token){
      state.token = token
    },
    saveShopId(state, shop_id){
      state.shop_id = shop_id
    }
  },
  actions: {
  },
  modules: {
  }
})
