import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index.vue')
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: () => import('../views/welcome.vue')
  },
  {
    path: '/form',
    name: 'form',
    component: () => import('../views/mobile.vue')
  },
  {
    path: '/pc',
    name: 'pc',
    component: () => import('../views/pc.vue')
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

// 避免跳转到当前网址的报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// router.beforeEach((to, from, next) => {
//   if(to.name == 'Service'){
//     window.location.href = 'https://mp.weixin.qq.com/s/Qd44GFyaNH05XV0JdX_nVw'
//   }
//   else{
//     next()
//   }
// })

router.afterEach((to, from) => {
  // if (to.params.shop_id) {
  //   const shop_id = to.params.shop_id;
  //   store.commit('saveShopId', shop_id)
  // }
})


export default router
